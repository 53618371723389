export const setOptionsValues = (arr, option = 'title') => {
  if (arr.length > 0) {
    return arr.map((el) => {
      return { value: el?.city?.id, label: el[option] };
    });
  }
  return [];
};

export const getKeys = (keysArray, filteredValues, setKeys, key, isTitle) => {
  const keys = new Set([]);

  if (filteredValues.length && !keysArray.length) {
    filteredValues.forEach((el) => {
      keys.add(getEditFormat(el?.[key], isTitle));
    });
    const newKeys = new Set();
    Array.from(keys).forEach((el) => {
      newKeys.add({ value: el, label: el });
    });
    setKeys(Array.from(newKeys));
  }
};

export const getEditFormat = (str, isTitle = false, lower = false, trim = false) => {
  if (isTitle && str) {
    if (str.split(' ').length) {
      return toTitleCase(str.split(' ').join('').trim().toLowerCase());
    } else {
      return toTitleCase(str.trim().toLowerCase());
    }
  } else if (lower && str) {
    str.trim().toLowerCase();
  }
  else if (str && trim) {
    return str.trim();
  }
  else if (str) {
    return str;
  } else {
    return '';
  }
};

export const PAGE_LIMIT = 30;

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const sortTimeResults = (result) => {
  result?.sort((a, b) => {
    if (!a?.result) return 1;
    if (a?.result === b?.result) return 0;
    if (a?.result > b?.result) return 1;
    else return -1;
  });

  return result;
};

export const getCityName = (eventCities, id) => eventCities?.find((el) => el?.city?.id === Number(id));

export const getFilterValue = (item, label, trim = false, lower = false, isTitle = false) => {
  return item ? [{ value: getEditFormat(item, isTitle, lower, trim), label: getEditFormat(label, isTitle, lower, trim) }] : [];
};

export const isActive = (item, keys = []) => keys?.find((el) => getEditFormat(el?.value, false, true) === getEditFormat(item, false, true));

export function toTitleCase(str) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  else return str;
};
