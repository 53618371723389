import { ReactComponent as IconLeft } from '@/assets/images/icons/icon-left.svg'
import { ReactComponent as IconVk } from '@/assets/images/icons/icon-vk.svg'
import { ReactComponent as IconYoutube } from '@/assets/images/icons/icon-youtube.svg'
import { ReactComponent as Logos } from '@/assets/images/logos-group.svg'

import styles from './Footer.module.scss'

function Footer() {
  const scrollToTop = () => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.inner}>
        <div className={styles.logosWrap}>
          <Logos className={styles.logos} />

          <ul className={styles.socials}>
            <li className={styles.socialsItem}>
              <a href="https://www.youtube.com/channel/UCN8vRARh9HeGBJryD41kCwA/">
                <IconYoutube className={styles.socialsIcon} />
                <span className="visually-hidden">Ютуб</span>
              </a>
            </li>
            <li className={styles.socialsItem}>
              <a href="https://vk.com/zabegrf">
                <IconVk className={styles.socialsIcon} />
                <span className="visually-hidden">ВКонтакте</span>
              </a>
            </li>
          </ul>
        </div>

        <nav className={styles.nav}>
          <ul className={styles.navList}>
            <li className={styles.navItem}>
              <a className={styles.navLink} href="#formats">
                Принять участие
              </a>
            </li>
            <li className={styles.navItem}>
              <a className={styles.navLink} href="#faq">
                FAQ
              </a>
            </li>
            <li className={styles.navItem}>
              <a className={styles.navLink} href="https://забег.рф/soglasie.pdf">
                Согласие родителей
              </a>
            </li>
            <li className={styles.navItem}>
              <a className={styles.navLink} href="#formats">
                Форматы забега
              </a>
            </li>
            <li className={styles.navItem}>
              <a className={styles.navLink} href="https://heroleague.ru/click/9A5FBD10">
                Тренировки
              </a>
            </li>
            {/*<li className={styles.navItem}>*/}
            {/*  <a className={styles.navLink} href="#">*/}
            {/*    Федеральное положение*/}
            {/*  </a>*/}
            {/*</li>*/}
            <li className={styles.navItem}>
              <a className={styles.navLink} href="#startPack">
                Пакет участника
              </a>
            </li>
            <li className={styles.navItem}>
              <a className={styles.navLink} href="https://забег.рф/polozhenie2024.pdf">
                Проект положения
              </a>
            </li>
            {/* <li className={styles.navItem}>
              <a className={styles.navLink} href="https://heroleague.ru/corp#corpForm/">
                Корпоративное участие
              </a>
            </li> */}
          </ul>
        </nav>

        <div className={styles.copyright} onClick={scrollToTop}>
          <button className={styles.upToTop} type="button">
            <IconLeft className={styles.upToTopIcon} />
            <span className="visually-hidden">прокрутить страницу наверх</span>
          </button>

          <div>
            <p className={styles.companyName}>АНО Лига Героев</p>
            <p className={styles.companyInn}>ИНН 9709034888</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
