import { times } from './times'

export const setCitiesOptions = (arr, value = 'id', label = 'address') => {
  if (arr.length > 0) {
    const filteredCities = []

    arr.forEach((el) => {
      filteredCities.push({
        value: el.public_id,
        label: el[label] + ' ' + times.getShortNumberDate(el.start_time),
      })
    })

    return filteredCities
  }
  return []
}

export const isOnlineCity = (city) => {
  return city?.event_public_id.includes('online')
}

export const getRightNames = (num, one, two, five) => {
  let n = Math.abs(num)

  n %= 100

  if (n >= 5 && n <= 20) {
    return five
  }

  n %= 10

  if (n === 1) {
    return one
  }
  if (n >= 2 && n <= 4) {
    return two
  }

  return five
}
