import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

import { ReactComponent as IconInfo } from '@/assets/images/icons/icon-info.svg'
import { ReactComponent as IconMenu } from '@/assets/images/icons/icon-menu.svg'
import { ReactComponent as LogoPsb } from '@/assets/images/logo-psb.svg'
import { ReactComponent as Logos } from '@/assets/images/logos-group.svg'

import MenuModal from '@/components/MenuModal/MenuModal'
import PartnerInfo from '@/components/Modals/PartnerInfo/PartnerInfo'
import SelectCityModal from '@/components/Modals/SelectCityModal/SelectCityModal'

import { getStoreSelectedCity } from '@/store/slices/app'

import styles from './Header.module.scss'

const mainPartner = {
  adInfo: {
    company: 'ПАО "Промсвязьбанк"',
    inn: 7744000912,
    token: '2Vtzqvo4638',
  },
}

function Partner({ handleOpenMainPartnerModal }) {
  return (
    <div className={styles.partnerWrap}>
      <LogoPsb className={styles.logoPsb} />
      <span className={styles.partnerLabel}>Генеральный спонсор</span>

      {handleOpenMainPartnerModal && (
        <button className={styles.btnInfo} onClick={handleOpenMainPartnerModal}>
          <span className="visually-hidden">Информация о партнёре</span>
          <IconInfo width={20} height={20} />
        </button>
      )}
    </div>
  )
}

function Header({ onChangeCity }) {
  const selectedCity = useSelector(getStoreSelectedCity)
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [isSelectCity, setIsSelectCity] = useState(false)
  const [isOpenPartnerInfo, setIsOpenPartnerInfo] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' })

  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        <div className={styles.logosWrap}>
          <Link to="/">
            <Logos className={styles.logos} />
          </Link>

          {!isTablet && <Partner handleOpenMainPartnerModal={() => setIsOpenPartnerInfo(true)} />}
        </div>

        <div className={styles.actions}>
          <div className={styles.selectCityWrap}>
            {!isMobile && (
              <div className={styles.selectCity} onClick={() => setIsSelectCity((prev) => !prev)}>
                <p>Выбери свой город:</p>
                <p className={styles.cityName}>{selectedCity?.city?.name_ru ?? 'Выбрать город'}</p>
              </div>
            )}

            <SelectCityModal
              onChangeCity={onChangeCity}
              onClose={setIsSelectCity}
              openMenuModal={() => setIsOpenMenu(true)}
              isOpen={isSelectCity}
              isOverlay={isMobile}
            />
          </div>

          <button className={styles.buttonMenu} onClick={() => setIsOpenMenu(true)} type="button">
            <IconMenu className={styles.buttonMenuIcon} />
            <span className="visually-hidden">Открыть меню</span>
          </button>

          <div>
            <MenuModal
              isOpen={isOpenMenu}
              onClose={() => setIsOpenMenu(false)}
              openSelectCityModal={() => setIsSelectCity(true)}
            />
          </div>
        </div>

        {isTablet && <Partner handleOpenMainPartnerModal={() => setIsOpenPartnerInfo(true)} />}

        <PartnerInfo partner={mainPartner} isOpen={isOpenPartnerInfo} onClose={() => setIsOpenPartnerInfo(false)} />
      </div>
    </header>
  )
}

export default Header
