import React, { useEffect } from 'react';

import styles from './Pagination.module.scss';


const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const Pagination = (props) => {
  const { onPageChanged, currentPage = 1, setCurrentPage } = props;
  let { totalRecords = null, pageLimit = 30, pageNeighbours = 0 } = props;

  pageLimit = typeof pageLimit === 'number' ? pageLimit : 30;
  totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;

  pageNeighbours =
    typeof pageNeighbours === 'number'
      ? Math.max(0, Math.min(pageNeighbours, 2))
      : 0;

  const totalPages = Math.ceil(totalRecords / pageLimit);

  // this.state = { currentPage: 1 };


  useEffect(() => {
    gotoPage(1);
  }, []);

  const gotoPage = page => {
    // const { onPageChanged = f => f } = this.props;

    const currentPage = Math.max(0, Math.min(page, totalPages));

    const paginationData = {
      currentPage,
      totalPages: totalPages,
      pageLimit: pageLimit,
      totalRecords: totalRecords,
    };

    setCurrentPage(currentPage);
    onPageChanged(paginationData);
    // this.setState(() => onPageChanged(paginationData));
  };

  const handleClick = (page, evt) => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = evt => {
    evt.preventDefault();
    gotoPage(currentPage - pageNeighbours * 2 - 1);
  };

  const handleMoveRight = evt => {
    evt.preventDefault();
    gotoPage(currentPage + pageNeighbours * 2 + 1);
  };

  const fetchPageNumbers = () => {
    // const totalPages = totalPages;
    // const currentPage = this.state.currentPage;
    // const pageNeighbours = pageNeighbours;

    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];
      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);

        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }
      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };


  // if (totalRecords) return null;

  if (totalPages === 1) return null;

  // const { currentPage } = this.state;
  const pages = fetchPageNumbers();

  return (
    <>
      <nav aria-label="Countries Pagination">
        <ul className={styles.pagination}>
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <li key={index} className={styles.pageItem}>
                  <a
                    className={styles.pageLink}
                    href="/"
                    aria-label="Previous"
                    onClick={handleMoveLeft}
                  >
                    <span aria-hidden="true">&laquo;</span>
                    {/*<span className="sr-only">Previous</span>*/}
                  </a>
                </li>
              );

            if (page === RIGHT_PAGE)
              return (
                <li key={index} className={styles.pageItem}>
                  <a
                    className={styles.pageLink}
                    href="/"
                    aria-label="Next"
                    onClick={handleMoveRight}
                  >
                    <span aria-hidden="true">&raquo;</span>
                    {/*<span className="sr-only">Next</span>*/}
                  </a>
                </li>
              );

            return (
              <li
                key={index}
                className={`${styles.pageItem} ${currentPage === page ? styles.pageItemActive : ''}`}
              >
                <a
                  className={styles.pageLink}
                  href="/"
                  onClick={e => handleClick(page, e)}
                >
                  {page}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export default Pagination;
