import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import { ReactComponent as IconDown } from '@/assets/images/icons/icon-down.svg'
import { ReactComponent as IconPlus } from '@/assets/images/icons/icon-plus.svg'

import HiddenScroll from '@/components/HideScroll/HideScroll'

import { getStoreCities } from '@/store/slices/app'

import styles from './SelectCityModal.module.scss'

function SelectCityModal({ onChangeCity, onClose, openMenuModal, isOpen, isCenter = false, isOverlay = true }) {
  const cities = useSelector(getStoreCities)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [filteredCities, setFilteredCities] = useState([])
  const searchRef = useRef()
  const menuRef = useRef(null)

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  useEffect(() => {
    setFilteredCities(cities)
  }, [cities])

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onClose()
    }
  }

  const handleBackMenuClick = () => {
    onClose(false)
    openMenuModal()
  }

  if (!isOpen) return null

  const handleChangeSearch = () => {
    if (searchRef.current.value.length === 0) {
      setFilteredCities(cities)
    } else {
      // setActiveLetter(searchRef.current.value)
      filtersCities(searchRef.current.value)
    }
  }

  const onClickCity = (city) => {
    onChangeCity(city.public_id)
    onClose(false)
  }

  const filtersCities = (letter = 'А') => {
    let newCities = []

    if (letter?.length === 1 || letter === 'Все') {
      newCities = cities.filter(
        (item) => letter.toLowerCase() === item.address.slice(0, letter.length).toLowerCase() || letter === 'Все'
      )
    } else if (letter?.length > 1) {
      newCities = cities.filter((item) => item.address.toLowerCase().includes(letter.toLowerCase()))
    }

    setFilteredCities(newCities)
  }

  return (
    <>
      <div className={`${styles.selectCityModal} ${isCenter ? styles.selectCityModalCenter : ''}`} ref={menuRef}>
        {isMobile && (
          <div className={styles.buttons}>
            <div>
              {!isCenter && (
                <button className={styles.backMenuBtn} onClick={handleBackMenuClick}>
                  <IconDown className={styles.backMenuBtnIcon} width={13} height={13} />
                  <span className="visually-hidden">Вернуться к меню</span>
                </button>
              )}
            </div>

            <button className={styles.closeBtn} onClick={() => onClose(false)}>
              <IconPlus className={styles.closeBtnIcon} />
              <span className="visually-hidden">Закрыть выбор города</span>
            </button>
          </div>
        )}

        <input
          className={styles.input}
          ref={searchRef}
          onChange={handleChangeSearch}
          type="search"
          placeholder="Введите название вашего города"
        />

        <div className={styles.labelWrap}>
          <p className={styles.label}>Крупные города</p>
        </div>

        <ul className={styles.items}>
          {filteredCities.map((item) => (
            (item.city.id === 999999 || item.city.id === 524901 || item.city.id === 498817) && (
              <li className={styles.item} key={item.public_id}>
                {item.external_url && <a className={styles.itemLink} href={item.external_url} target="_blank" rel="noopener noreferrer">{item.address}</a>}
                {!item.external_url && <span className={styles.itemValue} onClick={() => onClickCity(item)}>{item.address}</span>}
              </li>
            )
          ))}
          {filteredCities.map(
            (item, index) =>
              (index < 10 && item.city.id !== 524901 && item.city.id !== 498817) && (
                <li className={styles.item} key={item.public_id}>
                  {item.external_url && <a className={styles.itemLink} href={item.external_url} target="_blank" rel="noopener noreferrer">{item.address}</a>}
                  {!item.external_url && <span className={styles.itemValue} onClick={() => onClickCity(item)}>{item.address}</span>}
                </li>
              )
          )}
        </ul>

        {/*<a className={styles.link} href="#">*/}
        {/*  Федеральное положение*/}
        {/*</a>*/}
      </div>

      {isOverlay && <div className={styles.menuModalOverlay} />}

      {isMobile && <HiddenScroll />}
    </>
  )
}

export default SelectCityModal
