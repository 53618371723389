export const getDate = (data) => {
  const months = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
  ]
  const days = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота']

  const time = {}
  const unixTime = new Date(data * 1000)

  time.year = unixTime.getFullYear()
  time.month = months[unixTime.getMonth()]
  time.numMonth = unixTime.getMonth()
  time.date = unixTime.getDate()
  time.day = days[unixTime.getDay()]
  time.hour = unixTime.getHours()
  time.minute = unixTime.getMinutes()

  return time
}

export const times = {
  getTime(date = 1) {
    const obj = getDate(date)
    const str = obj.hour + ':' + obj.minute
    return str
  },

  getDay(date = 1) {
    const obj = getDate(date)
    const str = obj.day
    return str
  },

  getDayOfWeek(date = 1) {
    const obj = getDate(date)
    const str = `${obj.date} ${obj.month}`
    return str
  },

  getFullDate(date = 1) {
    const obj = getDate(date)
    const str = `${obj.date} ${obj.month} ${obj.year}`
    return str
  },

  getNumberDate(date = 1) {
    const obj = getDate(date)
    const day = obj.date < 10 ? `0${obj.date}` : obj.date
    const month = obj.numMonth < 9 ? `0${obj.numMonth + 1}` : obj.numMonth + 1
    const year = obj.year.toString()

    return `${year}-${month}-${day}`
  },

  getShortNumberDate(date = 1) {
    const obj = getDate(date)
    const day = obj.date < 10 ? `0${obj.date}` : obj.date
    const month = obj.numMonth < 9 ? `0${obj.numMonth + 1}` : obj.numMonth + 1

    return `${day}.${month}`
  },
}

export const getCurrentPrice = (prices) => {
  const currentTime = Date.now() / 1000
  return prices.find((p) => currentTime < p.start_date)
}
