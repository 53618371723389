import React, { useEffect, useState } from 'react'

import Footer from '@/components/Footer/Footer'
import Head from '@/components/Head/Head'
import Header from '@/components/Header/Header'
import Loader from '@/components/Loader/Loader'
import Pagination from '@/components/Pagination/Pagination'
import CustomSelect from '@/components/Select/Select'

import styles from './index.module.scss'
import results  from './results.json'
import { PAGE_LIMIT, getEditFormat, getFilterValue, getKeys, isActive, sortTimeResults, toTitleCase } from './utils/utils'

const DEFAULT_CITY = 'Москва'

const ResultsTable = ({ onChangeCity }) => {
  const [eventCities, setEventCities] = useState([])
  const [city, setCity] = useState([{ value: DEFAULT_CITY, label: DEFAULT_CITY }])
  const [members, setMembers] = useState(sortTimeResults(results))
  const [filteredData, setFilteredData] = useState([])
  const [filteredValues, setFilteredValues] = useState(sortTimeResults(results))
  const [searchingData, setSearchingData] = useState([])
  const [loading, setLoading] = useState(true)
  const [companyNames, setCompanyNames] = useState([])
  const [formatsNames, setFormatsNames] = useState([])
  const [genders, setGenders] = useState([])
  const [format, setFormat] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [gender, setGender] = useState('')
  const [filteredKeys, setFilteredKeys] = useState({ city: [{ value: DEFAULT_CITY, label: DEFAULT_CITY }] })
  const [currentPage, setCurrentPage] = useState(1)
  const [searchField, setSearchField] = useState('')
  const [pagination, setPagination] = useState({
    currentPage: 1,
    current_values: [],
    totalPages: null,
    values: [],
    query: null,
    totalRecords: filteredValues.length,
  })

  const onPageChanged = (data) => {
    const { currentPage, totalPages, pageLimit, filteredVal = filteredValues, totalRecords } = data
    const values = filteredVal

    const offset = (currentPage - 1) * pageLimit
    const current_values = values.slice(offset, offset + pageLimit)

    setPagination({
      ...pagination,
      currentPage: currentPage,
      current_values: current_values,
      totalPages,
      totalRecords,
    })
  }

  const handleActivityFilter = (e, type, event) => {
    if (event) {
      const isChecked = filteredKeys[type]?.find((item) => item.value === e[0]?.value)

      if (isChecked) e = filteredKeys[type]?.filter((item) => item.value !== e[0]?.value)
    }

    if (type === 'city') {
      setFilteredKeys({ ...filteredKeys, city: e })
      setCity(e)
    } else if (type === 'format') {
      setFilteredKeys({ ...filteredKeys, format: e })
      setFormat(e)
    } else if (type === 'gender') {

      setFilteredKeys({ ...filteredKeys, gender: e })
      setGender(e)
    } else if (type === 'company') {
      setFilteredKeys({ ...filteredKeys, company: e })
      setCompanyName(e)
    }
  }

  const handleChangeSearch = (evt) => {
    setSearchField(evt.target.value)

    let data = filteredData.length ? filteredData : members
    data = !evt.target.value ? members : data

    const filteredValues = data.filter((item) => {
      const itemName = JSON.stringify(item).toLowerCase()
      return itemName.includes(evt.target.value.toLowerCase())
    })

    setFilteredValues(filteredValues)
    setSearchingData(filteredValues)

    onPageChanged({
      currentPage: pagination.currentPage,
      totalPages: pagination.totalPages,
      pageLimit: PAGE_LIMIT,
      totalRecords: pagination.totalRecords,
      filteredVal: filteredValues,
    })

    if (!evt.target.value) {
      setSearchingData([])
    }
  }

  useEffect(() => {
    getKeys(companyNames, results, setCompanyNames, 'company')
    getKeys(formatsNames, results, setFormatsNames, 'distance', true)
    getKeys(genders, results, setGenders, 'gender')
    getKeys(eventCities, results, setEventCities, 'city')

  }, [filteredValues])

  useEffect(() => {

    let data = searchingData.length ? searchingData : members

    if (filteredKeys?.company?.length) {
      data = data.filter(d => filteredKeys.company.find((item) => d?.company?.trim() === item.value))
    }
    if (filteredKeys?.format?.length) {
      data = data.filter(d => filteredKeys.format.find((item) => getEditFormat(d?.distance, true) === item.value))

    }
    if (filteredKeys?.gender?.length) {
      data = data.filter(d => filteredKeys.gender.find((item) => d?.gender?.trim() === item.value))
    }

    if (filteredKeys?.city?.length) {
      data = data.filter(d => filteredKeys.city.find((item) => d?.city?.trim() === item.value))
    }

    setFilteredValues(data)
    setFilteredData(data)
    setCurrentPage(1)

    onPageChanged({
      currentPage: 1,
      totalPages: Math.round(pagination.totalRecords / PAGE_LIMIT),
      pageLimit: PAGE_LIMIT,
      totalRecords: data.length,
      filteredVal: data,
    })

    if (filteredKeys?.company?.length === 0 || filteredKeys?.gender?.length === 0 || filteredKeys?.gender?.length === 0 || filteredKeys?.city?.length === 0) {
      setFilteredData([])
    }

  }, [filteredKeys, searchingData])

  return (
    <>
      <Header onChangeCity={onChangeCity} />
      <Head />

      <div className={styles.tableResults}>
        <section className={styles.wrapper}>
          <h2 className={styles.title}>Результаты Забег</h2>

          <div className={styles.selects}>
            <div className={styles.selectsGroup}>
              <div>
                <CustomSelect
                  data-filter="city"
                  handleSelectChange={(e) => handleActivityFilter(e, 'city')}
                  value={city}
                  prefix="select-city"
                  name="city"
                  isMulti
                  options={eventCities}
                  placeholder="Выберите ваш город"
                  styles={styles}
                  isSearch={true}
                  closeMenuOnScroll={() => false}
                />
              </div>
              <div>
                <CustomSelect
                  data-filter="company"
                  handleSelectChange={(e) => handleActivityFilter(e, 'company')}
                  value={companyName}
                  prefix="select-city"
                  name="company"
                  options={companyNames}
                  isMulti
                  placeholder="Выберите компанию"
                  styles={styles}
                  isSearch={true}
                  closeMenuOnScroll={() => false}
                />
              </div>
            </div>

            <div className={styles.selectsGroup}>
              <div>
                <CustomSelect
                  data-filter="format"
                  handleSelectChange={(e) => handleActivityFilter(e, 'format')}
                  value={format}
                  prefix="select-city"
                  isMulti
                  name="format"
                  options={formatsNames}
                  placeholder="Выберите формат"
                  styles={styles}
                  isSearch={true}
                  closeMenuOnScroll={() => false}
                />
              </div>
              <div>
                <CustomSelect
                  data-filter="gender"
                  handleSelectChange={(e) => handleActivityFilter(e, 'gender')}
                  value={gender}
                  prefix="select-city"
                  name="gender"
                  isMulti
                  options={genders}
                  placeholder="Выберите пол"
                  styles={styles}
                  isSearch={true}
                  closeMenuOnScroll={() => false}
                />
              </div>
            </div>
          </div>
          <div className={styles.search}>
            <input
              className={styles.searchField}
              type="search"
              placeholder="Поиск участника"
              onChange={handleChangeSearch}
              value={searchField}
            />
            <button className={styles.searchBtn} type="button">искать</button>
          </div>

          {pagination.current_values.length ? <div className={styles.tableWrap}>
            <table className={`${styles.table} ${styles.tableResults}`}>
              <thead>
                <tr>
                  <th className={styles.titleColumn}>Место</th>
                  <th className={styles.titleColumn}>Компания</th>
                  <th className={styles.titleColumn}>Фамилия</th>
                  <th className={styles.titleColumn}>Имя</th>
                  <th className={styles.titleColumn}>Формат</th>
                  <th className={styles.titleColumn}>Номер</th>
                  <th className={styles.titleColumn}>Пол</th>
                  <th className={styles.titleColumn}>Время чистое</th>
                  <th className={styles.titleColumn}>Город</th>
                </tr>
              </thead>
              <tbody>
                {pagination.current_values.map((item, index) => (
                  <React.Fragment key={new Date() + index * currentPage}>
                    <tr className={styles.tableRow} >
                      <td className={styles.tableCell}>{currentPage === 1 ? index + 1 : ((index + 1) + (PAGE_LIMIT * (currentPage - 1)))}</td>
                      <td
                        className={`${styles.tableCell} ${styles.pointer} ${isActive(item?.company, filteredKeys?.company) && styles.activeColor}`}
                        onClick={(e) => handleActivityFilter(getFilterValue(item?.company, item?.company, true), 'company', e)}>
                        {item?.company}</td>
                      <td className={styles.tableCell}>{toTitleCase(item.lastname)}</td>
                      <td className={styles.tableCell}>{toTitleCase(item.firstname)}</td>
                      <td
                        className={`${styles.tableCell} ${styles.pointer} ${isActive(item?.distance, filteredKeys?.format) && styles.activeColor}`}
                        onClick={(e) => handleActivityFilter(getFilterValue(item?.distance, item?.distance, true, true, true), 'format', e)}>
                        {getEditFormat(item.distance, true)}</td>
                      <td className={styles.tableCell}>{item.number}</td>
                      <td
                        className={`${styles.tableCell} ${styles.pointer} ${isActive(item?.gender, filteredKeys?.gender) && styles.activeColor}`}
                        onClick={(e) => handleActivityFilter(getFilterValue(item?.gender, item?.gender, true), 'gender', e)}>
                        {item.gender}</td>
                      <td className={styles.tableCell}>{item.result}</td>
                      <td className={`${styles.tableCell} ${styles.pointer} ${isActive(item?.city, filteredKeys?.city) && styles.activeColor}`}
                        onClick={(e) => handleActivityFilter(getFilterValue(
                          item?.city,
                          item?.city), 'city', e)}
                      >{item?.city}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div> :
            <>{loading ? <Loader /> : <span style={{ color: 'black' }}>Нет результатов</span>}</>}

          {filteredValues.length > 0 &&
            <Pagination
              totalRecords={filteredValues.length}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={1}
              onPageChanged={onPageChanged}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          }
        </section>
      </div>
      <Footer />
    </ >
  )
}

export default ResultsTable
