import Select from 'react-select';

import { customStyles } from './custom';


const CustomSelect = ({
  options,
  Logo = null,
  styles,
  error = '',
  disabled = false,
  handleSelectChange = () => { },
  handleFocus = () => { },
  defVal = '',
  prefix = 'prefix',
  title = '',
  name = '',
  placeholder = '',
  isSearch = false,
  customS = customStyles,
  closeMenuOnScroll = (param = true) => param,
  isMulti = false,
  ...rest
}) => {

  return (
    <div className={styles.inputWrap}>
      {Logo && (
        <Logo
          className={`${styles.selectIcon} ${error && styles.selectIconError}`}
        />
      )}
      <Select
        {...rest}
        onFocus={handleFocus}
        onChange={handleSelectChange}
        defaultValue={defVal}
        className={`${prefix}-container`}
        classNamePrefix={prefix}
        isSearchable={isSearch}
        name={name}
        isLoading={!options.length}
        isMulti={isMulti}
        options={options}
        placeholder={placeholder}
        styles={customS}
        defaultMenuIsOpen={false}
        closeMenuOnScroll={closeMenuOnScroll}
      />
    </div>
  );
};

export default CustomSelect;
