import { useEffect, useState } from 'react'

import { useRealTimeTimer } from '@/hooks/useTimer'
import { getRightNames } from '@/utils/common'
import { times } from '@/utils/times'

import styles from './Timer.module.scss'

function Timer({ selectedCity }) {
  const [date, setDate] = useState(1716135284)
  const timeLeft = useRealTimeTimer(times.getNumberDate(date))

  useEffect(() => {
    if (selectedCity?.start_time) {
      setDate(selectedCity.start_time)
    }
  }, [selectedCity])

  return (
    <span className={styles.timer}>
      <span className={styles.daysWrap}>
        <span className={styles.number}>{timeLeft.days}</span>
        <span className={styles.labelNum}>{getRightNames(timeLeft.days, 'День', 'Дня', 'Дней')} </span>
      </span>

      <div className={styles.timesWrap}>
        <span className={styles.numWrap}>
          <span className={styles.number}>{timeLeft.hours}</span>
        </span>

        <span className={styles.divider}>:</span>

        <span className={styles.numWrap}>
          <span className={styles.number}>{timeLeft.minutes}</span>
        </span>

        <span className={styles.divider}>:</span>

        <span className={styles.numWrap}>
          <span className={styles.number}>{timeLeft.seconds}</span>
        </span>
      </div>
    </span>
  )
}

export default Timer
