import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

import Timer from '@/components/Timer/Timer'

import { getStoreSelectedCity } from '@/store/slices/app'
import { times } from '@/utils/times'

import styles from './Head.module.scss'

function DesktopMain({ selectedCity, isExpiredTime }) {
  return (
    <>
      {isExpiredTime && (
        <div className={styles.dateWrap}>
          <Timer selectedCity={selectedCity} />

          <p className={styles.date}>{times.getDayOfWeek(selectedCity?.start_time)} <span className={styles.titlePartWhite}>2024</span></p>
        </div>
      )}
      <p className={styles.title}>
        Заряжай <span className={styles.titlePart}>бег</span>ом
      </p>
    </>
  )
}

function MobileMain({ selectedCity, isExpiredTime }) {
  return (
    <>
      <div className={styles.titleWrap}>
        <p className={styles.title}>
          Заряжай <br /> <span className={styles.titlePart}>бег</span>ом
        </p>

        {isExpiredTime && (
          <div className={styles.timer}>
            <Timer selectedCity={selectedCity} />
          </div>
        )}
      </div>
      {isExpiredTime && <p className={styles.date}>{times.getDayOfWeek(selectedCity?.start_time)}</p>}
    </>
  )
}

function Head() {
  const selectedCity = useSelector(getStoreSelectedCity)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const currentDate = moment()
  const secondDate = moment.unix(selectedCity?.start_time)
  const isExpiredTime = currentDate.isBefore(secondDate)

  return (
    <section className={styles.head}>
      <div className={styles.inner}>
        {isMobile ? <MobileMain selectedCity={selectedCity} isExpiredTime={isExpiredTime} /> : <DesktopMain selectedCity={selectedCity} isExpiredTime={isExpiredTime} />}

        <div className={styles.buttons}>
          {isExpiredTime ? (
            <a className={`${styles.link} ${styles.linkFormats}`} href="#formats">
              Онлайн Забег
            </a>
          ):(
            <Link className={`${styles.link} ${styles.linkFormats}`} to="https://heroleague.ru/results">Результаты</Link>
          )}
          <a className={`${styles.link} ${styles.linkCorp}`} href="/результаты">
            Корпоративные результаты
          </a>
          <a className={`${styles.link} ${styles.linkTraining}`} href="https://heroleague.ru/click/9A5FBD10">
            Тренировки
          </a>
          <a className={`${styles.link} ${styles.linkTraining}`} href="https://photo.heroleague.ru/">
            Фото
          </a>
          <a className={`${styles.link} ${styles.linkTraining}`} href="#feedback">
            Обратная связь
          </a>
        </div>
      </div>
    </section>
  )
}

export default Head
