export const customStyles = {
  container: (provided) => ({
    ...provided,
    border: '2px solid #ffffff',
    borderRadius: '10px',
    color: 'black',
    backgroundColor: '#ffffff',
  }),

  menu: (provided, state) => ({
    ...provided,
    width: '100%',
    color: 'black',
    padding: '1.875rem 1.25rem 1.25rem 0.625rem',
    backgroundColor: '#ffffff',
    zIndex: '1',
    borderRadius: '10px',
  }),

  menuList: (provided) => ({
    ...provided,
    maxHeight: 200,
    color: 'black',
  }),

  option: (provided, { data, isFocused }) => {
    const disabled = data?.size && data?.value <= 1;

    return {
      ...provided,
      padding: '0.5rem 0.75rem',
      fontFamily: 'DIN Pro',
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1.222,
      backgroundColor: isFocused
        ? '#1caed7'
        : 'transparent',
      color: disabled && 'rgba(255,255,255, 0.1)',
      cursor: 'pointer',
      transition: 'background-color 0.2s ease',
      borderRadius: '10px',
    };
  },

  control: (_, { selectProps: { width } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0.625rem 1rem 1.5rem',
    cursor: 'pointer',
  }),

  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    marginTop: 'auto',
  }),

  // indicatorsContainer: () => ({ padding: '0' }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0',
  }),
  dropdownIndicator: (provided) => ({ ...provided, padding: '0' }),

  singleValue: () => ({
    color: 'black',
  }),

  multiValue: (provided) => ({
    ...provided,
    color: '#ffffff',
    backgroundColor: '#1caed7',
  }),

  multiValueLabel: (provided) => ({
    ...provided,
    color: '#ffffff',
  }),

  placeholder: () => ({
    fontSize: '0.75rem',
    lineHeight: '1.166',
    color: 'black',
  }),
};




