import img from '@/assets/images/farewell-modal.webp'
import { ReactComponent as IconTelegram } from '@/assets/images/icons/icon-telegram.svg'
import { ReactComponent as IconVk } from '@/assets/images/icons/icon-vk.svg'

import Modal from '@/components/Modals/Modal/Modal'

import styles from './FarewellModal.module.scss'

function FarewellModal({ isShow, onClose }) {
  return (
    <Modal isOpen={isShow} onClose={onClose}>
      <div className={styles.modalInner}>
        <div className={styles.content}>
          <p className={styles.title}>Друзья!</p>
          <p className={styles.text}>
            Спасибо вам, что были с нами. Мы прощаемся, но ненадолго. Ждите новостей, Ваш ЗаБег.рф
          </p>

          <ul className={styles.socials}>
            <li className={styles.socialsItem}>
              <a href="https://vk.com/zabegrf/">
                <IconVk className={styles.socialsIcon} />
                <span className="visually-hidden">ВКонтакте</span>
              </a>
            </li>
            <li className={styles.socialsItem}>
              <a href="https://t.me/halfmarathon_zabegrf">
                <IconTelegram className={styles.socialsIcon} />
                <span className="visually-hidden">Телеграм</span>
              </a>
            </li>
          </ul>
        </div>
        <img className={styles.img} src={img} width={644} height={280} alt="" />
      </div>
    </Modal>
  )
}

export default FarewellModal
