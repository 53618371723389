import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import { ReactComponent as IconPlus } from '@/assets/images/icons/icon-plus.svg'
import { ReactComponent as IconVk } from '@/assets/images/icons/icon-vk.svg'
import { ReactComponent as IconYoutube } from '@/assets/images/icons/icon-youtube.svg'

import HiddenScroll from '@/components/HideScroll/HideScroll'

import { getStoreCityFromFormats, getStoreSelectedCity } from '@/store/slices/app'

import styles from './MenuModal.module.scss'

const menuItems = [
  {
    label: 'Принять участие',
    link: '#formats',
  },
  {
    label: 'Форматы забега',
    link: '#formats',
  },
  {
    label: 'Пакет участника',
    link: '#startPack',
  },
  {
    label: 'FAQ',
    link: '#faq',
  },
  {
    label: 'Тренировки',
    link: 'https://heroleague.ru/click/9A5FBD10',
  },
  {
    label: 'Согласие родителей',
    link: 'https://забег.рф/soglasie.pdf',
  },
]

function MenuModal({ isOpen, onClose, openSelectCityModal }) {
  const selectedCity = useSelector(getStoreSelectedCity)
  const cityFromFormats = useSelector(getStoreCityFromFormats)
  const menuRef = useRef(null)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onClose()
    }
  }

  const handleSelectCityClick = () => {
    onClose()
    openSelectCityModal()
  }

  if (!isOpen) return null

  return (
    <>
      <div className={styles.menuModal} role="dialog" ref={menuRef}>
        <button className={styles.closeBtn} onClick={onClose}>
          <IconPlus className={styles.closeBtnIcon} />
          <span className="visually-hidden">Закрыть меню</span>
        </button>

        <div className={styles.top}>
          <p><a className={styles.navLink} href="https://heroleague.ru/myevents">Личный кабинет</a></p>

          <a className={styles.button} href="https://heroleague.ru/myevents">
            Войти
          </a>
        </div>

        {isMobile && (
          <div className={styles.selectCityWrap}>
            <p className={styles.selectCityLabel}>Ваш город</p>

            <div className={styles.cityWrap}>
              <p className={styles.cityName}>{selectedCity?.city?.name_ru ?? 'Выберите город'}</p>

              <button className={styles.choiceCityBtn} onClick={handleSelectCityClick}>
                Выбрать
              </button>
            </div>
          </div>
        )}

        <nav className={styles.nav}>
          <ul className={styles.navList}>
            {/*<li className={styles.navItem}>*/}
            {/*  <a className={styles.navLink} href="#">*/}
            {/*    Федеральное положение*/}
            {/*  </a>*/}
            {/*</li>*/}
            {menuItems.map((item) => (
              <li className={styles.navItem} key={item.link}>
                <a className={styles.navLink} onClick={onClose} href={item.link}>
                  {item.label}
                </a>
              </li>
            ))}
            {cityFromFormats?.info?.legals && (
              <li className={styles.navItem}>
                <a className={styles.navLink} onClick={onClose} href={cityFromFormats.info.legals}>
                  Положение
                </a>
              </li>
            )}
          </ul>
        </nav>

        <div className={styles.bottom}>
          <ul className={styles.socials}>
            <li className={styles.socialsItem}>
              <a href="https://www.youtube.com/channel/UCN8vRARh9HeGBJryD41kCwA/">
                <IconYoutube className={styles.socialsIcon} />
                <span className="visually-hidden">Ютуб</span>
              </a>
            </li>
            <li className={styles.socialsItem}>
              <a href="https://vk.com/zabegrf/">
                <IconVk className={styles.socialsIcon} />
                <span className="visually-hidden">ВКонтакте</span>
              </a>
            </li>
          </ul>

          <p className={styles.bottomLinkWrap}>
            <span>все проекты:</span>{' '}
            <a className={styles.bottomLink} href="https://heroleague.ru/">
              heroleague.ru
            </a>
          </p>
        </div>
      </div>

      <div className={styles.menuModalOverlay} />

      {isMobile && <HiddenScroll />}
    </>
  )
}

export default MenuModal
